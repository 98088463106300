import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import VideoConference from "../../components/SingleCase/VideoConference"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForVideoConference from "../../information/Cases/dataForVideoConference"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class VideoConferencePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForVideoConference

    return (
      <section className="singleProject Videoconference">
        <SEO
          title="Video Conference Software Development Case — OS-System"
          description="Experience hassle-free web-based video conferencing with our user-friendly app. Built on WebRTC and XMPP protocols for high performance and low resource usage."
          canonical={`${mainUrl}/cases/video-conference/`}
        />
        <Layout showFormPoint={300}>
          <VideoConference data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
