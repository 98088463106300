import {
  BgHead,
  Picture1,
  Picture2,
  HeadSection,
} from "../../images/SingleCase/VideoConference"

const dataForVideoConference = {
  type: "project",
  smart: true,
  classComponent: "Videoconference",
  nextLink: "cases/wikigrads/",
  prevLink: "cases/webrtc/",
  data: {
    title: "Video Сonference App",
    subtitle:
      "Turnkey video conferencing system  for a web browser participation in a video conferences with internal and external users",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "The customer is an international telecommunication company which main specialty is development of VoIP products.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The challenge was to eliminate the need to go to the conference room and installation of any additional hardware or software components. To create a user friendly, simple, web browser based video conferencing system with enough performance to cover all business meetings needs.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "WebRTC",
            "SIP",
            "XMPP",
            "PHP",
            "Slim",
            "Java",
            "JS",
            "HTML",
            "CSS",
            "webpack",
            "MySQL",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 2,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "2014 - ongoing support",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The main features were implemented - audio and video conference, chat, screen sharing, document sharing, conference room to youtube and Facebook stream, video record on server side, possibility to connect to the conference for both web-clients and via SIP-phone.",
        "We developed the user interface part on javascript so the server is receiving as less load as possible to improve the “conferencing” part performance. We used WebRTC for the audio and video broadcast to make the application applicable to most of the users, signaling through xmpp have made the app online and realtime.",
        "All media and signalling is transmitted through the server to save the incoming traffic, and on top of that we also implemented simulcast for even more traffic savings and video quality optimization.",
        "For the back-end we used prosody which is perfectly works with XMPP protocol, cross platform, pretty much extensible and have very low resource usage, we also have used jitsi-videobridge due to its applicability with WebRTC, low latency and its advances video routing concepts. Also we have used Asterisk for PBX switching system integration to application and which made possible of VoIP and PSTN connection to the web-conferences.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Access to video conference room just via the web browser and using your PC with webcam and microphone",
        "Invitation of the users via email",
        "Users access - via browser or by calling a phone number, without the need to install any component",
        "Possibility to share your desktop and documents",
        "Conference scheduling (synced with Google calendars), reminders via email",
        "The conference room remains open as long as needed",
        "Possibility to invite as many participants as required (limit is only bandwidth of user)",
        "Recording of the video conference, including audio, video and desktop sharing ",
        "YouTube and Facebook conference streaming possibility ",
        "Possibility of sharing a link with other participants to work together on a document or inside an application",
        "Password secured conference rooms",
        "Video quality adjustment possibility",
      ],
    },
    images: {
      bgImage: BgHead,
      section2: Picture1,
      section3: Picture2,
      HeadSection: HeadSection,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
  ],
  link: "/cases/video-conference",
}

export default dataForVideoConference
