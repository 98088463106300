import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function VideoConference({ data }) {
  let i = 0
  return [
    <section className="bgBlock bgImage Videoconference" key={i++}>
      <div
        className="wave"
        style={{ backgroundImage: `url(${data.images.bgImage})` }}
      >
        <div className="container">
          <div className="row" id="video-conference-row">
            <div
              className="col-md-10 col-sm-8 col-md-offset-1 col-sm-offset-2"
              style={{ height: "100%" }}
            >
              <div className="titleBlock">
                <h1>Video Conference App</h1>
                <span>{data.subtitle}</span>
              </div>
            </div>
            <div className="headImg">
              <img src={data.images.HeadSection} alt="Video Conference App" />
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section className="Videoconference compose" key={i++}>
      <div className="bgImage">
        <OverviewComponent {...data} classComponent="" />

        <section
          className="VideoconferenceImg image"
          id="video-conference-image"
        >
          <div className="imageBlock"></div>
          <div className="container">
            <div className="row">
              <img
                src={data.images.section2}
                alt="Video Сonference Application"
              />
            </div>
          </div>
        </section>

        <section className="solution">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.solution.title}</h2>
                {data.solution.textFirst.map((item, i) => (
                  <p className="mb-4" key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section
          className="image-solution image"
          id="video-conference-solution"
        >
          <div className="imageBlock"></div>
          <div className="container">
            <div className="row">
              <img
                src={data.images.section3}
                alt="Video Сonference Application"
                className="solution-picture"
              />
            </div>
          </div>
        </section>

        <section className="solution last">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.delivered.title}</h2>
                <ul>
                  {data.delivered.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>,
  ]
}
